import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Acco from "./Acco";
import Tickets from "./Tickets";
import Lang from "../Lang";
// import Season from "./Season";
import Search from "../Search";

import styles from "./Quicklinks.styles";

const Quicklinks = ({ lang, menuOpen, sticky }) => {
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const css = styles();

	return (
		<div className={clsx(css.quicklinks, "mco-view-component-header_quicklinks")}>
			{isDesktop && (
				<>
					<Tickets />
					{/* <Season lang={lang} sticky={sticky} /> */}
					<Acco lang={lang} sticky={sticky} />
				</>
			)}
			{isMobile && (
				<>
					{!menuOpen && (
						<>
							<Tickets />
							{/* <Season lang={lang} sticky={sticky} /> */}
							<Acco lang={lang} sticky={sticky} />
						</>
					)}
					{menuOpen && (
						<>
							<Search />
							<Lang />
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Quicklinks;
