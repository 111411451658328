import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		alignItems: "center",
		display: "flex",
		marginLeft: "auto",
		marginRight: "auto",
		[theme.breakpoints.up("md")]: {
			left: "50%",
			marginLeft: -125,
			position: "absolute",
			top: 0
		},
		"@media (max-width: 420px)": {
			"& img": {
				width: 120
			}
		},
		"@media (max-width: 400px)": {
			"& img": {
				width: 100
			}
		}
	},
	logoSticky: {
		[theme.breakpoints.up("md")]: {
			left: "50%",
			marginLeft: -82,
			position: "absolute",
			transform: "translate(0,-100%)",
			top: 0
		}
	},
	logoStickyShow: {
		[theme.breakpoints.up("md")]: {
			transform: "translate(0,0)",
			transition: "transform 300ms ease"
		}
	},
	hide: {
		pointerEvents: "none",
		"& img": {
			transform: "translate(0,-100%)"
		}
	},
	image: {
		display: "block"
	}
}));

export default styles;
