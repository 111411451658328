import loadable from "@loadable/component";

const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactSingleImage02 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage02")
);
const ReactImageText01 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText01")
);
const ReactGallery04 = loadable(() => import("@micado-digital/react-gallery/ReactGallery04"));
const ReactSingleTeaser01 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser01")
);
const ReactTeaserList01 = loadable(() =>
	import("@micado-digital/react-teaser-list/ReactTeaserList01")
);
const ReactTeaser03 = loadable(() => import("@micado-digital/react-teaser/ReactTeaser03"));
const ReactWeather03 = loadable(() => import("@micado-digital/react-weather/ReactWeather03"));
const ReactSearchResults = loadable(() =>
	import("@micado-digital/react-search-results/ReactSearchResults")
);
const ReactSnowReport01 = loadable(() =>
	import("@micado-digital/react-snowreport/ReactSnowReport01")
);
const ReactWebcams01 = loadable(() => import("@micado-digital/react-webcams/ReactWebcams01"));
const ReactLiftState01 = loadable(() =>
	import("@micado-digital/react-liftstate/ReactLiftState01")
);
const ReactPistState01 = loadable(() =>
	import("@micado-digital/react-piststate/ReactPistState01")
);
const ReactFacilities01 = loadable(() =>
	import("@micado-digital/react-facilities/ReactFacilities01")
);
const TourismInfrastructuresTeaser = loadable(() =>
	import("@Elements/TourismInfrastructuresTeaser")
);
const ReactRates01 = loadable(() => import("@micado-digital/react-rates/ReactRates01"));
const ReactWeather02 = loadable(() => import("@micado-digital/react-weather/ReactWeather02"));
const CustomButton = loadable(() => import("@Elements/Button"));
const IntroCombo = loadable(() => import("@Elements/IntroCombo"));
const TeaserGrid = loadable(() => import("@Elements/TeaserGrid"));
const EventList = loadable(() => import("@Elements/EventList"));

const getJSXElement = (element, l, lang, sysLink, isMobile, season) => {
	const { REACT_APP_PATH } = process.env;
	const {
		addition,
		addition2,
		anchor,
		children,
		elements,
		id,
		media,
		option,
		option2,
		option4,
		reference,
		tag,
		teaser,
		text,
		textcontent,
		teasergroup,
		title,
		variant
	} = element;

	switch (tag) {
		case "basic-headline": {
			return (
				<Container
					key={id}
					tag={tag}
					variant={variant === 6 ? "overline" : (variant || 0) + 1}
					maxWidth="lg"
					anchor={anchor?.tag}
				>
					<ReactHeadline01 text={text} variant={variant === 6 ? 5 : variant} />
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={anchor?.tag}
					variant={variant === 1 ? "large" : ""}
				>
					<ReactText01 text={text} />
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={anchor?.tag}
					variant={variant === 0 ? "two-columns" : ""}
				>
					<ReactList01
						headlineVariant="h4"
						textcontent={textcontent}
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor?.tag}>
					<ReactImageText01
						addition2={addition2}
						alt={addition}
						headlineVariant="h4"
						option={option}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "basic-imagetext-mobile",
							sm: "basic-imagetext"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor?.tag}>
					<ReactGallery04
						autoplay={true}
						delay={5000}
						elements={elements}
						mediaFormats={{
							xs: "basic-gallery-preview"
						}}
						navNext="/img/icons/chevron-right.svg"
						navPrev="/img/icons/chevron-left.svg"
						loop={false}
						overlayMediaFormats={{
							xs: "basic-gallery"
						}}
						speed={1000}
						showPagination={true}
						showNavigation={true}
						breakpoints={{
							0: {
								slidesPerView: 1.2,
								spaceBetween: 16
							},
							500: {
								slidesPerView: 2.2,
								spaceBetween: 16
							},
							960: {
								slidesPerView: 3.2,
								spaceBetween: 24
							},
							1280: {
								slidesPerView: 3,
								spaceBetween: 24
							}
						}}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="h4"
						items={newTableArray}
						title={title}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactReferences01
						elements={elements}
						title={title}
						hasIcon={false}
						endIcon={
							<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} color="primary" />
						}
						headlineVariant="h4"
					/>
				</Container>
			);
		}

		case "basic-video": {
			if (!media) return <></>;

			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactVideo02
						media={media[0]}
						mediaFormats={{ xs: "video-mobile", sm: "video" }}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactHTML html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag} maxWidth="lg" anchor={anchor?.tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-dropdown": {
			return null;
		}

		case "basic-dropdown-group": {
			return (
				<Container
					key={id}
					tag={tag}
					maxWidth="lg"
					anchor={element?.children?.[0]?.anchor?.tag}
					variant={element?.children?.[0]?.variant === 1 ? "full-width" : ""}
				>
					<ReactDropDown01
						headlineVariant="h2"
						option={element?.children?.[0]?.option2}
						title={element?.children?.[0]?.title}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleteaser": {
			return (
				<Container
					key={id}
					maxWidth={false}
					tag={tag}
					style={{ padding: 0 }}
					anchor={anchor?.tag}
					variant={season}
				>
					<ReactSingleTeaser01
						buttonLabel={l("reactSingleTeaser01.button")}
						buttonSize={isMobile ? "small" : "medium"}
						headlineVariant={isMobile ? "h4" : "h3"}
						icon="/img/icons/chevron-right.svg"
						iconSize={24}
						teaser={teaser}
						media={teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: "basic-teaser"
						}}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const title = textcontent?.items?.find(item => item?.name === "Title")?.text;
			const text = textcontent?.items?.find(item => item?.name === "Text")?.text;
			const link = textcontent?.items?.find(item => item?.name === "URL")?.text;
			const media = elements?.find(item => item?.tag === "Image1")?.media;

			const teaser = {
				title: title,
				text: text,
				link: link,
				linkTarget: "_blank"
			};

			return (
				<Container
					key={id}
					maxWidth={false}
					tag={tag}
					anchor={anchor?.tag}
					style={{ padding: 0 }}
				>
					<ReactSingleTeaser01
						buttonLabel={l("reactSingleTeaser01.button")}
						buttonSize={isMobile ? "small" : "medium"}
						headlineVariant={isMobile ? "h4" : "h3"}
						icon="/img/icons/chevron-right.svg"
						iconSize={24}
						teaser={teaser}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "basic-teaser-mobile",
							sm: "basic-teaser"
						}}
					/>
				</Container>
			);
		}

		case "basic-singleimage": {
			if (variant === 1) {
				return (
					<Container
						key={id}
						tag={tag}
						maxWidth="xl"
						anchor={anchor?.tag}
						style={{ padding: 0 }}
					>
						<ReactSingleImage01
							addition={addition}
							option2={option2}
							media={media ? media?.[0] : {}}
							mediaFormats={{
								xs: "basic-singleimage-panorama-mobile",
								md: "basic-singleimage-panorama"
							}}
							mediaPath={REACT_APP_PATH}
							reference={reference}
							text={text}
							title={title}
						/>
					</Container>
				);
			} else {
				return (
					<Container key={id} tag={tag} anchor={anchor?.tag} style={{ padding: 0 }}>
						<ReactSingleImage02
							addition={addition}
							option2={option2}
							media={media ? media?.[0] : {}}
							mediaFormats={{
								xs: "basic-singleimage-mobile",
								md: "basic-singleimage"
							}}
							mediaPath={REACT_APP_PATH}
							reference={reference}
							text={text}
							title={title}
						/>
					</Container>
				);
			}
		}

		case "basic-form": {
			return (
				<Container key={id} maxWidth="lg" tag={tag} anchor={anchor?.tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST" key={id}>
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "basic-teaser": {
			if (!teasergroup?.items && teasergroup?.items?.length < 1) {
				return;
			}

			if (variant === 1) {
				return (
					<Container key={id} tag={tag} variant="grid" maxWidth={false} anchor={anchor?.tag}>
						<TeaserGrid
							button={{
								label: l("teaserGrid.buttonAll"),
								link: reference?.pageName ?? addition2,
								linkTarget: option4 === 1 ? "_blank" : "_top"
							}}
							items={teasergroup?.items}
							headline={title}
							season={season}
						/>
					</Container>
				);
			} else {
				return (
					<Container key={id} tag={tag} variant="slider" maxWidth={false} anchor={anchor?.tag}>
						<ReactTeaserList01
							autoplay={false}
							button={{
								color: "primary",
								link: reference?.pageName ?? addition2,
								linkTarget: option4 === 1 ? "_blank" : "_top",
								label: l("reactTeaserList01.buttonAll"),
								icon: "/img/icons/chevron-right.svg",
								iconSize: 24,
								variant: "contained"
							}}
							delay={5000}
							headline={title}
							headlineVariant="h2"
							loop={false}
							navNext="/img/icons/arrow-right.svg"
							navPrev="/img/icons/arrow-left.svg"
							showPagination={true}
							showNavigation={true}
							sliderBreakpoints={{
								0: {
									slidesPerView: 1.15,
									spaceBetween: 24
								},
								600: {
									slidesPerView: 2.2,
									spaceBetween: 24
								},
								960: {
									slidesPerView: 1.8,
									spaceBetween: 24
								},
								1440: {
									slidesPerView: 2.4,
									spaceBetween: 24
								},
								1920: {
									slidesPerView: 2.8,
									spaceBetween: 24
								},
								2560: {
									slidesPerView: 3.4,
									spaceBetween: 24
								}
							}}
							speed={1000}
							text={text}
							variant="slider"
						>
							{teasergroup?.items?.map(({ id, link, media, title, linkTarget }) => {
								return (
									<ReactTeaser03
										key={id}
										link={link}
										media={media ? media?.[0] : {}}
										mediaFormats={{
											xs: "basic-teaser-slider"
										}}
										mediaPath={REACT_APP_PATH}
										target={linkTarget}
										title={title ? title : ""}
									/>
								);
							})}
						</ReactTeaserList01>
					</Container>
				);
			}
		}

		case "extension-button": {
			const label = textcontent?.items?.find(item => item?.name === "valLabel")?.text;
			const link = elements?.find(item => item?.tag === "valLink")?.reference?.pageName;
			const externalLink = textcontent?.items?.find(
				item => item?.name === "valExternalLink"
			)?.text;
			const target = textcontent?.items?.find(item => item?.name === "valTarget")?.text;

			if (!link && !externalLink) {
				return;
			}

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<CustomButton
						label={label}
						link={link ? link : externalLink}
						target={externalLink && target === "True" ? "_blank" : "_top"}
					/>
				</Container>
			);
		}

		case "extension-weather": {
			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactWeather03
						lang={lang}
						url={`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.WeatherApi/GetDetailsAsJson.api`}
					/>
				</Container>
			);
		}

		case "extension-intro-combo": {
			const headline = textcontent?.items?.find(item => item?.name === "valHeadline")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;

			if (!headline) {
				return;
			}

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<IntroCombo headline={headline} text={text} />
				</Container>
			);
		}

		case "tourism-events-teaser": {
			let buttonLabel = textcontent?.items?.find(({ name }) => name === "buttonlabel")?.text;
			let categories = textcontent?.items?.find(({ name }) => name === "categories")?.text;
			let headline = textcontent?.items?.find(({ name }) => name === "linktitle")?.text;
			let holidayThemes = textcontent?.items?.find(
				({ name }) => name === "holidaythemes"
			)?.text;
			let link = textcontent?.items?.find(({ name }) => name === "linkurl")?.text;
			let selectedFilter = textcontent?.items?.find(({ name }) => name === "selected")?.text;
			let towns = textcontent?.items?.find(({ name }) => name === "towns")?.text;

			if (categories) categories = categories?.split("|")?.map(category => parseInt(category));
			if (holidayThemes)
				holidayThemes = holidayThemes?.split("|")?.map(holidayTheme => parseInt(holidayTheme));
			if (selectedFilter) selectedFilter = selectedFilter?.split("|");
			if (towns) towns = towns?.split("|")?.map(town => parseInt(town));

			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<EventList
						buttonLabel={buttonLabel}
						categories={categories}
						headline={headline}
						holidayThemes={holidayThemes}
						link={link}
						mediaFormats={{ xs: "event" }}
						selectedFilter={selectedFilter}
						towns={towns}
					/>
				</Container>
			);
		}

		case "mski-snowreport": {
			let client = null;
			let region = null;
			let location = null;

			textcontent?.items?.filter(item => {
				if (item.name === "valClient") {
					return (client = item?.text);
				}

				if (item.name === "valRegion") {
					return (region = item?.text);
				}

				if (item.name === "valLocation") {
					return (location = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth={false} tag={tag}>
					<div className="bg">
						<picture>
							<source
								media="(max-width: 767px)"
								srcSet={`/img/bg-snowreport-mobile.webp`}
								height={420}
								width={500}
							></source>
							<source
								media="(max-width: 1920px)"
								srcSet={`/img/bg-snowreport.webp`}
								height={485}
								width={1920}
							></source>
							<img
								src={`/img/bg-snowreport.webp`}
								alt="background"
								loading="lazy"
								height={485}
								width={1920}
							></img>
						</picture>
					</div>
					<Container maxWidth="md">
						<ReactSnowReport01
							client={client}
							iconSize={32}
							identifierAvalanche="avalance-level"
							identifierDateLastSnowfall="date-last-snowfall"
							identifierSnowHeightValley="snow-height-valley"
							identifierSnowHeightMountain="snow-height-mountain"
							lang={lang}
							location={location}
							region={region}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api`}
						/>
					</Container>
				</Container>
			);
		}

		case "mski-liftstate": {
			let client = null;
			let region = null;
			let season = null;
			let location = null;
			let selected = null;

			textcontent?.items?.filter(item => {
				if (item.name === "valClient") {
					return (client = item?.text);
				}

				if (item.name === "valRegion") {
					return (region = item?.text);
				}

				if (item.name === "valSeason") {
					return (season = item?.text);
				}

				if (item.name === "valLocation") {
					return (location = item?.text);
				}

				if (item.name === "facilities") {
					return (selected = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactLiftState01
						client={client}
						filter
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						skeletonItems={20}
						stateType="icon"
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Container>
			);
		}

		case "mski-piststate": {
			let client = null;
			let region = null;
			let season = null;
			let location = null;
			let selected = null;

			textcontent?.items?.filter(item => {
				if (item.name === "valClient") {
					return (client = item?.text);
				}

				if (item.name === "valRegion") {
					return (region = item?.text);
				}

				if (item.name === "valSeason") {
					return (season = item?.text);
				}

				if (item.name === "valLocation") {
					return (location = item?.text);
				}

				if (item.name === "pists") {
					return (selected = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactPistState01
						client={client}
						showLegend
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						skeletonItems={20}
						stateType="icon"
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Container>
			);
		}

		case "mski-webcams": {
			let client = null;
			let region = null;
			let selected = null;

			textcontent?.items?.filter(item => {
				if (item.name === "valClient") {
					return (client = item?.text);
				}

				if (item.name === "valRegion") {
					return (region = item?.text);
				}

				if (item.name === "webcams") {
					return (selected = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactWebcams01
						client={client}
						fallbackImage="/img/fallback-webcam.jpg"
						gridBreakpoints={{
							xs: 12,
							sm: 6,
							md: 4
						}}
						lang={lang}
						live
						overlaySize="xlarge"
						region={region}
						selected={selected}
						showLocation
						skeletonItems={12}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WebcamApi/ListWebcams.api`}
					/>
				</Container>
			);
		}

		case "mski-facilities": {
			let client = null;
			let region = null;
			let season = null;
			let location = null;
			let selected = null;
			let type = null;

			textcontent?.items?.filter(item => {
				if (item.name === "valClient") {
					return (client = item?.text);
				}

				if (item.name === "valRegion") {
					return (region = item?.text);
				}

				if (item.name === "valSeason") {
					return (season = item?.text);
				}

				if (item.name === "valLocation") {
					return (location = item?.text);
				}

				if (item.name === "valType") {
					return (type = item?.text);
				}

				if (item.name === "facilities") {
					return (selected = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactFacilities01
						client={client}
						filter
						lang={lang}
						location={location}
						region={region}
						showIcon={false}
						season={season}
						selected={selected}
						skeletonItems={20}
						stateType="icon"
						type={type}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Container>
			);
		}

		case "mski-weather": {
			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactWeather02
						lang={lang}
						station={3}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api`}
					/>
					<a
						href="https://www.geosphere.at/"
						target="_blank"
						rel="noreferrer"
						style={{
							color: "#003a56 ",
							display: "table",
							fontSize: 14,
							fontWeight: "normal",
							marginTop: 16
						}}
					>
						&copy; GeoSphere Austria
					</a>
				</Container>
			);
		}

		case "mski-rates": {
			let client = null;
			let region = null;
			let identifier = null;

			textcontent?.items?.filter(item => {
				if (item.name === "valClient") {
					return (client = item?.text);
				}

				if (item.name === "valRegion") {
					return (region = item?.text);
				}

				if (item.name === "valRates") {
					return (identifier = item?.text);
				}

				return null;
			});

			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactRates01
						client={client}
						headlineVariant="h4"
						lang={lang}
						rate={identifier}
						region={region}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.RateApi/GetRateTableJSON.api`}
					/>
				</Container>
			);
		}

		case "extension-search-result": {
			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<ReactSearchResults
						api={`${REACT_APP_PATH}/searchv2.json.api`}
						detailUrl={sysLink("search")}
						icon={<ReactSVGIcon src="/img/icons/arrow-right.svg" size={32} />}
					/>
				</Container>
			);
		}

		case "tourism-info-teaser": {
			return (
				<Container key={id} maxWidth="lg" tag={tag}>
					<TourismInfrastructuresTeaser element={element} id={id} lang={lang} />
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
