import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";
import Quicklinks from "../Quicklinks";

import styles from "./Animation.styles";

const Animation = ({ season }) => {
	const css = styles();
	const { REACT_APP_PATH } = process.env;
	const [textAnimation, setTextAnimation] = useState(false);
	const [overlayOpen, setOverlayOpen] = useState(false);
	const [pageData] = useContext(PageContext);
	const { animationID, lang = "de", layoutVariant } = pageData;

	useEffect(() => {
		setTimeout(() => {
			setTextAnimation(true);
		}, 1000);
	}, []);

	useEffect(() => {
		if (overlayOpen) {
			setOverlayOpen(true);
		}
	}, [overlayOpen]);

	return (
		<div
			className={clsx(css.animation, "mco-view-component-animation", {
				[css.fullscreen]: layoutVariant === "fullscreen",
				[css.headerVisible]: layoutVariant !== "no-animation",
				[css.overlayVisible]: overlayOpen,
				"mco-view-component-animation--text-visible": textAnimation
			})}
		>
			<ReactAnimation
				api={`${REACT_APP_PATH}/animationV3.json.api`}
				showBullets={true}
				id={animationID}
				lang={lang}
				mediaFormats={{
					xs:
						layoutVariant === "fullscreen"
							? "animation-fullscreen-mobile"
							: "animation-mobile",
					sm: layoutVariant === "fullscreen" ? "animation-fullscreen-hd" : "animation-hd",
					xl: layoutVariant === "fullscreen" ? "animation-fullscreen" : "animation"
				}}
				webpFallback
			/>
			<Quicklinks
				lang={lang}
				layoutVariant={layoutVariant}
				season={season}
				setOverlayOpen={setOverlayOpen}
			/>
		</div>
	);
};

export default Animation;
