export const defaultTheme = {
	typography: {
		fontFamily: "Noto Sans",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		"display-large": {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(38px, 8vw, 56px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		"display-medium": {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(32px, 8vw, 48px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		"display-small": {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(26px, 8vw, 42px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		"headline-large": {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(24px, 8vw, 32px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		"headline-medium": {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(20px, 8vw, 24px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		"headline-small": {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(18px, 8vw, 20px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		"title-large": {
			fontFamily: "Noto Sans",
			fontWeight: 700,
			fontSize: 16,
			lineHeight: 2
		},
		"title-medium": {
			fontFamily: "Noto Sans",
			fontWeight: 700,
			fontSize: 14,
			lineHeight: 2
		},
		"title-small": {
			fontFamily: "Nunito Sans",
			fontWeight: 600,
			fontSize: 14,
			letterSpacing: "1px",
			lineHeight: 1.3,
			textTransform: "uppercase"
		},
		"body-large": {
			fontFamily: "Noto Sans",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: 2
		},
		"body-medium": {
			fontFamily: "Noto Sans",
			fontWeight: 400,
			fontSize: "14px",
			lineHeight: 2
		},
		"body-small": {
			fontFamily: "Nunito Sans",
			fontWeight: 600,
			fontSize: 12,
			letterSpacing: "0.4px",
			lineHeight: 1.3,
			textTransform: "uppercase"
		},
		"label-large": {
			fontFamily: "Nunito Sans",
			fontWeight: 800,
			fontSize: 20,
			letterSpacing: "0.4px",
			lineHeight: 1.2
		},
		"label-medium": {
			fontFamily: "Nunito Sans",
			fontWeight: 800,
			fontSize: 18,
			letterSpacing: "0.4px",
			lineHeight: 1
		},
		"label-small": {
			fontFamily: "Nunito Sans",
			fontWeight: 800,
			fontSize: 16,
			letterSpacing: "0.4px",
			lineHeight: 1.2
		},
		h1: {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(38px, 8vw, 56px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		h2: {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(32px, 8vw, 48px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		h3: {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(26px, 8vw, 42px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		h4: {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(24px, 8vw, 32px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		h5: {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(20px, 8vw, 24px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		h6: {
			fontFamily: "Nunito Sans",
			fontSize: "clamp(18px, 8vw, 20px)",
			fontWeight: 900,
			lineHeight: 1.1
		},
		body1: {
			fontFamily: "Noto Sans",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: 2
		},
		body2: {
			fontFamily: "Noto Sans",
			fontWeight: 400,
			fontSize: "14px",
			lineHeight: 2
		},
		subtitle1: {
			fontFamily: "Noto Sans",
			fontWeight: 700,
			fontSize: 16,
			lineHeight: 2
		},
		subtitle2: {
			fontFamily: "Noto Sans",
			fontWeight: 700,
			fontSize: 14,
			lineHeight: 2
		},
		overline: {
			fontFamily: "Nunito Sans",
			fontWeight: 600,
			fontSize: 14,
			letterSpacing: "1px",
			lineHeight: 1.3,
			textTransform: "uppercase"
		},
		caption: {
			fontFamily: "Nunito Sans",
			fontWeight: 600,
			fontSize: 12,
			letterSpacing: "0.4px",
			lineHeight: 1.3,
			textTransform: "uppercase"
		},
		button: {
			fontFamily: "Nunito Sans",
			fontWeight: 800,
			fontSize: 18,
			letterSpacing: "0.4px",
			lineHeight: 1,
			textTransform: "uppercase"
		}
	},
	palette: {
		primary: {
			light: "#EE7660",
			main: "#E63311",
			dark: "#A3240C"
		},
		secondary: {
			light: "#547B8E",
			main: "#003A56",
			dark: "#00293D"
		},
		tertiary: {
			light: "#929D80",
			main: "#5D6C42",
			dark: "#333B24"
		},
		error: {
			light: "#F08080",
			main: "#D50B0B",
			dark: "#8B0101"
		},
		warning: {
			light: "#FFDE77",
			main: "#FFC105",
			dark: "#9F7804"
		},
		info: {
			light: "#77B6DA",
			main: "#0175B9",
			dark: "#01446C"
		},
		success: {
			light: "#B2D975",
			main: "#71B800",
			dark: "#477301"
		},
		grey: {
			50: "#F7F7F8",
			100: "#E5E6E6",
			200: "#D0D2D2",
			300: "#B0B3B5",
			400: "#969A9C",
			500: "#7C8183",
			600: "#636769",
			700: "#4A4D4F",
			800: "#313435",
			900: "#191A1A",
			A100: "rgba(0, 0, 0, 0.1)",
			A200: "rgba(0, 0, 0, 0.3)",
			A400: "rgba(0, 0, 0, 0.5)",
			A700: "rgba(0, 0, 0, 0.8)"
		},
		text: {
			primary: "#00202F",
			secondary: "#636769",
			disabled: "#D0D2D2"
		},
		divider: "#CCCCCC",
		background: {
			backdrop: "rgba(0, 0, 0, 0.5)",
			body: "#F7F7F8",
			dark: "#00293D",
			light: "#E5E5E5",
			header: "#003A56",
			menu: "#003A56",
			default: "#F7F7F8",
			winter: "#003A57",
			summer: "#333B24"
		},
		brand: {
			brand1: "#003A57",
			brand2: "#E74011",
			brand3: "#E63312"
		},
		shadows: {
			shadow1: "0px 1px 2px rgba(0, 0, 0, 0.24)",
			shadow2: "0px 5px 10px rgba(0, 0, 0, 0.12)",
			shadow3: "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 12px 24px rgba(0, 0, 0, 0.12)"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: "24px !important",
				boxShadow: "none !important",
				fontSize: "18px !important",
				fontWeight: "800 !important",
				height: "48px !important",
				letterSpacing: "0.4px !important",
				paddingLeft: "24px !important",
				paddingRight: "24px !important",
				textTransform: "unset !important"
			},
			sizeSmall: {
				borderRadius: "20px !important",
				fontSize: "16px !important",
				height: "40px !important"
			},
			sizeLarge: {
				borderRadius: "32px !important",
				fontSize: "20px !important",
				height: "64px !important",
				minWidth: "150px !important"
			},
			outlined: {}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	customTypography: {
		menu: {
			mobile: {
				level1: {
					fontFamily: "Nunito Sans",
					fontWeight: 900,
					fontSize: 28,
					lineHeight: 1.1
				},
				level2: {
					fontFamily: "Nunito Sans",
					fontWeight: 900,
					fontSize: 24,
					lineHeight: 1.3
				}
			},
			desktop: {
				level1: {
					fontFamily: "Nunito Sans",
					fontSize: 32,
					fontWeight: 900,
					lineHeight: 1.1
				},
				level2: {
					fontFamily: "Nunito Sans",
					fontWeight: 900,
					fontSize: 24,
					lineHeight: 1.3
				}
			}
		},
		footer: {
			metamenu: {
				fontFamily: "Noto Sans",
				fontWeight: 500,
				fontSize: 12,
				lineHeight: 1.5
			}
		}
	}
};
