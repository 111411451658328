import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import Weather from "./Weather";
import Liftstate from "./Liftstate";
import Piststate from "./Piststate";

import styles from "./Overlay.styles";

const Overlay = ({ quicklinkID, setQuicklinkID, season }) => {
	const [overlayOpen, setOverlayOpen] = useState(false);
	const css = styles();

	const handleClose = () => {
		setOverlayOpen(false);
		setQuicklinkID(null);
	};

	useEffect(() => {
		if (quicklinkID > 0) {
			setOverlayOpen(true);
		}
	}, [quicklinkID]);

	const getOverlayContent = () => {
		switch (quicklinkID) {
			case 1:
				return <Weather />;

			case 2:
				return <Liftstate season={season} />;

			case 3:
				return <Piststate />;

			default:
				break;
		}
	};

	return (
		<Drawer
			anchor="right"
			classes={{ paper: css.root }}
			open={overlayOpen}
			onClose={() => handleClose()}
		>
			<div className={css.container}>
				<div className={css.header}>
					<div className={css.close} onClick={() => handleClose()}>
						<ReactSVGIcon src="/img/icons/close.svg" size={24} />
					</div>
				</div>
				{getOverlayContent()}
			</div>
		</Drawer>
	);
};

export default Overlay;
