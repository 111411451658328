import React, { Suspense, useContext, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import Hidden from "@material-ui/core/Hidden";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";

import styles from "./Lang.styles";

const ReactLangSelection = loadable(() =>
	import("@micado-digital/react-lang-selection/ReactLangSelection")
);

const Lang = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const [profileData] = useContext(WebProfileContext);
	const [showLang, setShowLang] = useState(false);
	const { lang, meta: { links } = {} } = pageData;
	const { languages } = profileData;

	const { isoCode: currentLangShort } = languages?.find(item => item.isoCode === lang) || {};

	return (
		<>
			{profileData?.languages?.length > 1 && (
				<div
					className={clsx(css.lang, "mco-view-component-header-lang")}
					onClick={() => setShowLang(true)}
				>
					<Hidden xsDown>
						<span className={clsx(css.label, "mco-view-component-header-lang__label")}>
							{currentLangShort}
						</span>
					</Hidden>
					<Hidden smUp>
						<span className={clsx(css.label, "mco-view-component-header-lang__label")}>
							{currentLangShort}
						</span>
					</Hidden>
				</div>
			)}
			<Suspense fallback={<></>}>
				{showLang && (
					<ReactLangSelection
						languages={languages}
						currentLanguage={lang}
						links={links}
						setOverlay={setShowLang}
					/>
				)}
			</Suspense>
		</>
	);
};

export default Lang;
