import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	weather: {
		paddingTop: theme.spacing(9),
		paddingLeft: theme.spacing(4.5),
		[theme.breakpoints.up("md")]: {
			paddingLeft: 0
		},
		"& .mco-weater-02-day": {
			background: "none",
			borderBottom: "1px solid #547B8E",
			marginTop: theme.spacing(5),
			padding: 0,
			"&:last-child": {
				borderBottom: "none"
			},
			"& h2": {
				color: "#FFFFFF",
				fontSize: "clamp(20px, 8vw, 24px)",
				marginBottom: theme.spacing(1)
			},
			"& .mco-weater-02-day__info-text": {
				color: "#FFFFFF",
				marginBottom: theme.spacing(3),
				marginTop: 0
			},
			"& .mco-weater-02-day__times-wrapper": {
				display: "grid",
				gap: theme.spacing(1),
				gridTemplateColumns: "1fr 1fr 1fr",
				margin: 0,
				paddingBottom: theme.spacing(3),
				width: "auto",
				"& > div": {
					maxWidth: "100%",
					flexBasis: "100%",
					padding: 0
				},
				"& svg path": {
					fill: "#FFFFFF"
				}
			},
			"& .mco-weater-02-day__times-wrapper > div:last-child": {
				display: "none"
			},
			"& .mco-weater-02-day__information-wrapper": {
				display: "none"
			},
			"& .mco-weater-02-day-item > div + div": {
				flexDirection: "column !important"
			},
			"& .mco-weater-02-day-item__temperature": {
				color: "#FFFFFF",
				fontSize: 24,
				margin: 0,
				"&+ div": {
					display: "none"
				}
			},
			"& .mco-weater-02-day-item__headline": {
				color: theme.palette.secondary.light,
				fontFamily: "'Nunito Sans'",
				fontSize: 14,
				fontWeight: 800,
				letterSpacing: "0.4px",
				textTransform: "unset"
			}
		}
	},
	topWrapper: {
		[theme.breakpoints.up("md")]: {
			alignItems: "center",
			display: "flex",
			gap: theme.spacing(4),
			justifyContent: "space-between",
			marginBottom: theme.spacing(4)
		}
	},
	headline: {
		color: "#FFFFFF",
		position: "relative",
		"&::before": {
			background: theme.palette.primary.main,
			borderRadius: "50%",
			content: "''",
			height: 16,
			left: -32,
			position: "absolute",
			top: 8,
			width: 16
		},
		"&::after": {
			background: theme.palette.primary.main,
			content: "''",
			height: 2,
			left: -420,
			position: "absolute",
			top: 15,
			width: 400
		}
	},
	button: {
		display: "table",
		marginTop: theme.spacing(1),
		[theme.breakpoints.up("md")]: {
			marginTop: 0
		},
		"& button": {
			fontSize: "14px !important",
			padding: "0 !important",
			transition: theme.transitions.create(["color"], {
				duration: theme.transitions.duration.short
			}),
			"&:hover": {
				background: "none",
				color: theme.palette.primary.dark
			}
		}
	}
}));

export default styles;
