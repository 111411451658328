import React from "react";
import clsx from "clsx";
import MenuItem from "../MenuItem";

import styles from "./Level2.styles";

const Level2 = ({ items }) => {
	const css = styles();

	return (
		<div className={clsx(css.level2, "mco-view-component-header-menu-mobilemenu-level2")}>
			{items?.map(item => {
				return (
					<div key={item?.id}>
						<MenuItem item={item} level={2} />
					</div>
				);
			})}
		</div>
	);
};

export default Level2;
