import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		bottom: 0,
		display: "flex",
		gap: theme.spacing(0.75),
		justifyContent: "center",
		left: 0,
		padding: theme.spacing(3, 2),
		position: "absolute",
		width: "100%",
		zIndex: 1,
		[theme.breakpoints.up("md")]: {
			flexDirection: "column",
			gap: theme.spacing(1),
			left: "auto",
			padding: theme.spacing(5),
			right: 0,
			width: "auto"
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(1.5)
		}
	},
	fullscreen: {
		[theme.breakpoints.up("lg")]: {
			"& > div": {
				height: 88,
				width: 88,
				"& > span": {
					fontSize: 16
				}
			}
		}
	},
	item: {
		alignItems: "center",
		background: theme.palette.primary.main,
		borderRadius: "50%",
		color: "#FFFFFF",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		height: 64,
		justifyContent: "center",
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.short
		}),
		width: 64,
		"&:hover": {
			background: theme.palette.primary.dark
		},
		"@media (max-width: 370px)": {
			height: 60,
			width: 60
		},
		"@media (max-width: 350px)": {
			height: 56,
			width: 56
		},
		"& svg path": {
			fill: "#FFFFFF"
		}
	},
	label: {
		fontSize: 12,
		fontWeight: 600,
		lineHeight: 1.6
	}
}));

export default styles;
