import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		background: theme.palette.background.menu
	},
	megamenu: {
		display: "flex",
		flexDirection: "column",
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
		overflow: "hidden",
		position: "fixed",
		width: "100%",
		zIndex: theme.zIndex.drawer
	},
	desktopmenu: {
		height: "100vh",
		minHeight: "100%",
		padding: theme.spacing(2, 6),
		width: 932,
		"& .mco-view-component-desktopmenu__link": {
			opacity: 0.3,
			transition: "opacity 300ms ease",
			"&:hover": {
				opacity: 1
			}
		},
		"& .mco-view-component-desktopmenu__link--selected": {
			opacity: 1
		},
		"& .mco-view-component-desktopmenu__level1-item--active .mco-view-component-desktopmenu__link":
			{
				opacity: 1
			}
	},
	submenuActive: {
		"& .mco-view-component-desktopmenu__link--selected": {
			opacity: 1
		}
	},
	close: {
		alignItems: "center",
		background: theme.palette.primary.main,
		borderRadius: "50%",
		cursor: "pointer",
		display: "flex",
		height: 50,
		justifyContent: "center",
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.short
		}),
		width: 50,
		"&:hover": {
			background: theme.palette.primary.dark
		}
	}
}));

export default styles;
