import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {
		background: theme.palette.background.menu,
		width: "100%",
		[theme.breakpoints.up("md")]: {
			maxWidth: 930
		}
	},
	header: {
		alignItems: "center",
		background: theme.palette.background.menu,
		display: "flex",
		height: 90,
		left: 0,
		padding: theme.spacing(0, 2),
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 1,
		[theme.breakpoints.up("md")]: {
			left: "auto",
			maxWidth: 930,
			right: 0
		}
	},
	close: {
		alignItems: "center",
		background: theme.palette.primary.main,
		borderRadius: "50%",
		cursor: "pointer",
		display: "flex",
		height: 50,
		justifyContent: "center",
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.short
		}),
		width: 50,
		"&:hover": {
			background: theme.palette.primary.dark
		}
	},
	container: {
		padding: theme.spacing(2.5, 2, 12.5),
		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(7, 23, 8, 14)
		}
	}
}));

export default styles;
