import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	icons: {
		alignItems: "center",
		display: "flex",
		height: 50,
		gap: theme.spacing(1),
		[theme.breakpoints.up("md")]: {
			marginLeft: "auto"
		},
		[theme.breakpoints.down(500)]: {
			"& > div": {
				height: 40,
				width: 40
			}
		}
	},
	iconsSticky: {
		"& > div": {
			background: theme.palette.secondary.light
		}
	},
	user: {
		background: theme.palette.secondary.light,
		borderRadius: "50%",
		cursor: "pointer",
		display: "none",
		alignItems: "center",
		height: 50,
		justifyContent: "center",
		position: "relative",
		width: 50,
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.short
		}),
		[theme.breakpoints.up(380)]: {
			display: "flex"
		},
		[theme.breakpoints.up("md")]: {
			background: theme.palette.secondary.main
		},
		"&:hover": {
			background: theme.palette.secondary.dark
		},
		"& svg": {
			color: "#FFFFFF",
			display: "block"
		}
	},
	cart: {
		background: theme.palette.secondary.light,
		borderRadius: "50%",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		height: 50,
		justifyContent: "center",
		position: "relative",
		width: 50,
		transition: theme.transitions.create(["background"], {
			duration: theme.transitions.duration.short
		}),
		[theme.breakpoints.up("md")]: {
			background: theme.palette.secondary.main
		},
		"&:hover": {
			background: theme.palette.secondary.dark
		},
		"& svg": {
			color: "#FFFFFF",
			display: "block"
		}
	},
	cartItems: {
		alignItems: "center",
		background: theme.palette.primary.main,
		borderRadius: "50%",
		bottom: 35,
		color: "#FFFFFF",
		display: "flex",
		fontSize: 10,
		fontWeight: 700,
		height: 17,
		justifyContent: "center",
		pointerEvents: "none",
		position: "absolute",
		right: 0,
		width: 17
	}
}));

export default styles;
