import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./Logo.styles";

const Logo = ({ layoutVariant, sticky }) => {
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = styles();

	return (
		<>
			<div
				className={clsx(css.logo, "mco-view-component-header__logo", {
					[css.hide]: screenMD && sticky && layoutVariant !== "no-animation"
				})}
			>
				<ReactLink to="/">
					<img
						alt="logo"
						className={clsx(css.image, "mco-view-component-header-logo__image")}
						height={screenMD && layoutVariant !== "no-animation" ? 137 : 90}
						src="/img/logos/logo.svg"
						width={screenMD && layoutVariant !== "no-animation" ? 250 : 164}
					/>
				</ReactLink>
			</div>
			{screenMD && layoutVariant !== "no-animation" && (
				<div
					className={clsx(css.logoSticky, "mco-view-component-header__logo", {
						[css.logoStickyShow]: screenMD && sticky
					})}
				>
					<ReactLink to="/">
						<img
							alt="logo"
							className={clsx(css.image, "mco-view-component-header-logo__image")}
							height={90}
							src="/img/logos/logo.svg"
							width={164}
						/>
					</ReactLink>
				</div>
			)}
		</>
	);
};

export default Logo;
