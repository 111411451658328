import { useEffect } from "react";

const useScrollToLocation = offset => {
	useEffect(() => {
		if (window.location?.hash) {
			const scrollToHashElement = () => {
				const { hash } = window.location;
				const elementToScroll = document.querySelector(
					`div[data-anchor="${hash?.replace("#", "")}"]`
				);

				if (!elementToScroll) return;

				window.scrollTo({
					top: elementToScroll?.offsetTop - offset,
					behavior: "smooth"
				});
			};

			setTimeout(() => {
				scrollToHashElement();
				window.addEventListener("hashchange", scrollToHashElement);
				return window.removeEventListener("hashchange", scrollToHashElement);
			}, 1000);
		} else {
			setTimeout(() => {
				const links = document?.querySelectorAll(`a`);
				const targetContainers = document?.querySelectorAll(`div[data-anchor]`);

				if (links) {
					for (const $anchor of links) {
						targetContainers?.forEach(item => {
							$anchor?.addEventListener(`click`, event => {
								if (
									event?.target?.hash?.replace("#", "") === item?.getAttribute("data-anchor")
								) {
									event?.preventDefault();
									item?.scrollIntoView({ behavior: "smooth" });
								}
								return null;
							});
						});
					}
				}
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useScrollToLocation;
