import React, { useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import localDE from "@localDE";
import localEN from "@localEN";
import MobileContainer from "./Level1";
import duplicateParentAsFirstChild from "./utils/duplicateParentAsFirstChild";

import styles from "./MobileMenu.styles";

const MobileMenu = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { lang, menuGroups } = pageData;
	const mainMenuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Hauptmenü");
	const ref = useRef(null);

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useEffect(() => {
		disableBodyScroll(ref.current);

		return () => {
			clearAllBodyScrollLocks();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		duplicateParentAsFirstChild(mainMenuGroup?.items, l("menu.overview"));
	}, [l, mainMenuGroup?.items]);

	return (
		<div
			className={clsx(css.mobilemenu, "mco-view-component-header-menu-mobilemenu")}
			ref={ref}
		>
			<div
				className={clsx(css.container, "mco-view-component-header-menu-mobilemenu__container")}
			>
				<MobileContainer items={mainMenuGroup?.items} />
			</div>
		</div>
	);
};

export default MobileMenu;
