import React, { useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles/";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import UserData from "@micado-digital/react-ui/components/UserData";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import { defaultTheme } from "./themes/default";
import CSSProperties from "./utils/CSSProperties";
import Ui from "@micado-digital/react-ui/components/Ui";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Views from "./views";
import "./css/index.css";
import "./css/ski-shop/main.css";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";
import "./css/index.css";

const theme = createTheme(defaultTheme);

const App = () => {
	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<BookmarkRoot>
							<WebPageData>
								<UserData>
									<Routes>
										<Route path="/*" element={<Views />} />
									</Routes>
								</UserData>
							</WebPageData>
						</BookmarkRoot>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
