import React, { useContext } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";

import styles from "./Tickets.styles";

const Tickets = () => {
	const [ui, setUi] = useContext(UiContext);
	const screenMD = useMediaQuery(theme => theme.breakpoints.up("md"));
	const sysLink = useSystemLinks();
	const css = styles();

	return (
		<div className={clsx(css.tickets, "mco-view-component-header__quicklinks-tickets")}>
			<ReactLink
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
				to={sysLink("tickets") || "/"}
			>
				<ReactSVGIcon className={css.icon} size={24} src="/img/icons/tickets.svg" />
				{screenMD && <span>Tickets</span>}
			</ReactLink>
		</div>
	);
};

export default Tickets;
