import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	search: {
		alignItems: "center",
		color: "#FFFFFF",
		cursor: "pointer",
		display: "flex",
		fontWeight: 600,
		fontSize: 16,
		gap: theme.spacing(1)
	}
}));

export default styles;
