import React, { useContext } from "react";
import PageContext from "@PageContext";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import ReactPistState01 from "@micado-digital/react-piststate/ReactPistState01";
import ReactFacilities01 from "@micado-digital/react-facilities/ReactFacilities01";
import ReactLink from "@micado-digital/react-link/ReactLink";
import Local from "@Local";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Piststate.styles";

const Piststate = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang = "de" } = pageData;
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<>
			<div className={css.piststate}>
				<div className={css.topWrapper}>
					<Typography className={css.headline} variant="h4" component="p">
						<Local identifier="overlay.headlinePiststate" l={l} />
					</Typography>
					<ReactLink className={css.button} to={sysLink("liftstate")}>
						<Button
							color="primary"
							variant="text"
							endIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} />}
						>
							{l("overlay.button")}
						</Button>
					</ReactLink>
				</div>
				<ReactPistState01
					client="https://sgm.bergbahnen-stjohann.at"
					filter={false}
					lang={lang}
					location="stjohann"
					region="stjohann"
					showLegend={false}
					skeletonItems={20}
					stateType="icon"
					url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
				/>
			</div>
			<div className={css.skitour}>
				<div className={css.topWrapper}>
					<Typography className={css.headline} variant="h4" component="p">
						<Local identifier="overlay.headlineSkitour" l={l} />
					</Typography>
				</div>
				<ReactFacilities01
					client="https://sgm.bergbahnen-stjohann.at"
					filter={false}
					lang={lang}
					region="stjohann"
					showIcon={false}
					skeletonItems={3}
					stateType="icon"
					url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					type="skitour-route"
				/>
			</div>
		</>
	);
};

export default Piststate;
