import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	menuItem: {
		alignItems: "center",
		color: theme.palette.primary.main,
		display: "flex",
		gap: theme.spacing(2),
		justifyContent: "space-between"
	},
	level1: {
		color: "#FFFFFF",
		...theme?.customTypography?.menu?.mobile?.level1,
		padding: theme.spacing(1, 0)
	},
	level1Selected: {
		color: theme.palette.primary.main
	},
	level2: {
		color: "#FFFFFF",
		...theme?.customTypography?.menu?.mobile?.level2,
		padding: theme.spacing(0.75, 0)
	},
	level2Selected: {
		color: theme.palette.primary.main
	}
}));

export default styles;
