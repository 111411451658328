import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(10),
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(10)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(10)
		},
		"& .mco-view-component-container-basic-teaser--slider, .mco-view-component-container-basic-teaser--grid, .mco-view-component-container-basic-singleteaser":
			{
				marginTop: theme.spacing(4)
			},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(15),
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(15)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(15)
			},
			"& > .mco-view-component-container-extension-intro-combo:first-child": {
				marginTop: theme.spacing(20)
			},
			"& .mco-view-component-container-basic-teaser--slider, .mco-view-component-container-basic-teaser--grid, .mco-view-component-container-basic-singleteaser":
				{
					marginTop: theme.spacing(8)
				}
		},
		"& .mco-dropdown-01__details > div": {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(4),
			padding: theme.spacing(4, 0),
			"& > div": {
				padding: 0
			}
		}
	}
}));

export default styles;
